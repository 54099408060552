import React from 'react';
import MESSAGES from '@messages';
import SchedulePage from '../templates/schedule-page';

const Harmonogram = () => (
  <SchedulePage
    title={MESSAGES.SCHEDULE_VERTYKALNA_KIDS_TITLE}
    metaTitle={MESSAGES.SCHEDULE_VERTYKALNA_KIDS_META_TITLE}
    color="yellow"
    id="cllthwcam0000mqqusls4zpab"
  />
);

export default Harmonogram;
